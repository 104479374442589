

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

@keyframes color {

    100%,
    0% {
        stroke: var(--accent);
    }

    50% {
        stroke: var(--accent);
    }


    80%,
    90% {
        stroke: var(--accent);
    }
}




.Loader__showbox{
    position: absolute;
    top: 0;
    right: 0; 
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.911);
    z-index: 55;
    --loader-width: 40px;
        
    // end demo-specific

    .Loader__loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-55%) translateX(-50%);
        margin: 0px auto;
        width: var(--loader-width);

        &:before {
            content: '';
            display: block;
            padding-top: 100%;
        }
    }

    .Loader__circle {
        animation: rotate 2s linear infinite;
        height: 100%;
        transform-origin: center center;
        width: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }


    .path {
        stroke-dasharray: 100, 200;
        stroke-dashoffset: 10;
        stroke: var(--accent);
        animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
        stroke-linecap: round;
    }

    &.Loader__showbox--big {

        --loader-width: 60px;
        
    }
    &.fixed {

        position: fixed;
        
    }
    &.static {

        position: static;
        
    }
}