
.InputQty{
    height: 41px;
    width: 80px;
    color: #7B7B7B;
    display: flex;
    
    input{
        width: 40px;
        border: 1px solid #D9D9D9;
        background-color: #fff;
        font-size: 11px;
        color: inherit;
        text-align: center;
        border-radius: 0;
        margin: 0;
        
        &:focus{
            outline-color: #ccc;
        }
    }

    .btn-minus,
    .btn-plus{
        width: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: inherit;
        background-color: #F8F8F8;
        border: 1px solid #D9D9D9;
        padding: 3px;
        border-radius: 0;
        
        transition: 333ms;

        &:not([disabled]):hover{
            background-color: #eee;
        }
    }
    .btn-plus{
        margin-left: -1px;
    }
    .btn-minus{
        margin-right: -1px;
    }
}