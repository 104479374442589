.SummaryList__wrapper{
    position: relative;
    isolation: isolate;
    z-index: 3;

    max-width: 100%;
    // overflow: hidden;
    
    @include media-breakpoint-up(xl){    
        position: sticky;
        bottom: 0;
    }
    @include media-breakpoint-down(xl){    
        .default-wrapper {
            padding-left: 0;
            padding-right: 0;
        }
    }

}
.SummaryList__shadow{
    position: absolute;
    z-index: -1;
    height: 10px;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: #000 0 0 20px -4px;
}

.SummaryList{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 100%;

    


    @include media-breakpoint-up(xl){
        flex-direction: row;

    }
}


.SummaryList__item{
    display: block;
    width: auto;
    flex-grow: 1;
    
    padding: 15px;
    
    border-bottom: 1px solid #eee;
    &:last-child{
        border-bottom: none;
    }
    
    @include media-breakpoint-up(xl){
        border-right: 1px solid #eee;
        
    }

    &.actions{
        display: flex;
        align-items: center;
        justify-content: center;
        .btn{
            margin: 2px;
        }
    }
    @include media-breakpoint-up(xl){
        &.SummaryList__item-title{
            width: 160px;
            flex-grow: 0;
            flex-shrink: 0;   
        }
        &.SummaryList__item-name{
            width: 210px;
            flex-grow: 0;
            flex-shrink: 0;   
        }
        &.SummaryList__item-addons{
            width: 240px;
            flex-grow: 0;
            flex-shrink: 0;   
        }
    }
}

.SummaryList__item-label{
    
    // font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 14px;
    color: #000000;
    opacity: 0.4;
    margin-bottom: 0.7em;
}
.SummaryList__item-value{

    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
    margin-bottom: 0.5em;
}