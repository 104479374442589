

@keyframes zoomIn{
    from{
        opacity: 0;
        transform: scale(0);
    }
}

@keyframes fadeOut{
    to{
        opacity: 0;
        transform: scale(0);
    }
}


