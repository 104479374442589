@import "mixins";



:root{
    --main-x-gutter: 20px;

    --yellow: #FFD124;
    --render-bg: #FFD124;
    --text-gray-dark: hsla(0, 0%, 0%, 0.6);
    --text-gray: hsla(0, 0%, 0%, 0.4);
    --accent: #000;
    --dark-accent: #888;
    --dark-accent-hover: #333;
    --radius: 10px;
    --shadow-1:  0px 0px 20px rgba(173, 174, 185, 0.25);

    --border-color: #ccc;

    --render-height-mobile: 250px;
    
    @include media-breakpoint-up(xxl){
        --main-x-gutter: 80px;
    }
}



// 4. Include any optional Bootstrap components as you like
// @import "../node_modules/bootstrap/scss/root";
// @import "../node_modules/bootstrap/scss/reboot";
// @import "../node_modules/bootstrap/scss/type";
// @import "../node_modules/bootstrap/scss/images";
// @import "../node_modules/bootstrap/scss/containers";
// @import "../node_modules/bootstrap/scss/grid";
// @import "../node_modules/bootstrap/scss/buttons";
// @import "../node_modules/bootstrap/scss/forms";
// 5. Add additional custom code here

// @import "../node_modules/bootstrap/scss/helpers";
// @import "../node_modules/bootstrap/scss/utilities";



// Utilities
// @import "../node_modules/bootstrap/scss/utilities/api";

@import './scss/typography.scss';
@import './scss/animations.scss';
@import './scss/btn.scss';