
.bg-white{
    background-color: #fff;
}

.bg-light{
    background-color: #E5E5E5;
}

.bg-yellow{
    background-color: #FFD124;
}