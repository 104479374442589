@use "/src/mixins.scss";

@import "~bootstrap/scss/bootstrap";
@import "custom";
@import "scss/grid.scss";
@import "scss/bg.scss";
@import "scss/wrappers.scss";

@import "./components/SummaryList";

body{
  background-color: #fff;
  color: #000;

  font-family: 'Montserrat', sans-serif;
}

$changeOrientationOn: xl;

/*
 *  Scrollbar
 */

::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #fafafa;
}
 
::-webkit-scrollbar
{
    width: 5px;
    height: 5px;
    background-color: #fafafa;
}

::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    background-color: var(--dark-accent);
}
html, body, #root{
    height: 100%;

}
body{
    overflow-x: hidden;
    // background: radial-gradient(43.44% 95.21% at 50% 50%, #EFF0F1 0%, #FFFFFF 100%);
}
img{
    max-width: 100%;
}
.App{
    min-height: 100%;
    display: flex;
    flex-direction: column;
    .added_to_cart:focus, .added_to_cart:hover, .btn:focus, .btn:hover, .button:focus, .button:hover, button:focus, button:hover, input[type=submit]:focus, input[type=submit]:hover {
        background-color: unset;
        color: unset;
        text-decoration: unset;
        border-color: unset;
    }
    @import "scss/btn.scss";

}


.col-form{
    order: 1;
    padding-top: 1rem;
}

@include media-breakpoint-up($changeOrientationOn){

    .col-form{
        order: 0;
        padding-bottom: 100px;
    }
    .step-design{
        .col-form{
            order: 1;
        }
    }
}

.line-right{
    border-right: 1px solid #D9D9D9;
}

.main-content{
    flex-grow: 1;
    display: flex;
    width: 100%;
    position: relative;
    >.default-wrapper{
        // display: flex;
        width: 100%;
        >.row{
            height: 100%;
        //    flex-grow: 1;
        }
    }
}


.sticky-content--mobile{
    position: sticky;
    top: 0;
    z-index: 22;
    background: #fff;
}
@include media-breakpoint-up(md){
    .sticky-content--mobile{
    position: relative;
    }
    .sticky-content--desktop{
        position: sticky;
        top: 0;
    }
}

.Render{
    display: block;
    position: relative;
    background: radial-gradient(43.44% 95.21% at 50% 50%, #EFF0F1 0%, #FFFFFF 100%);
    
    // height: var(--render-height-mobile);
    
    aspect-ratio: 16/9;
    width: 100%;
    // height: 300px;

    
    @include media-breakpoint-up(md){
    
        // height: max( var(--height-y) - 500px, 300px );
        // max-height: 500px;

    }
    .SketchfabRender{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
    }
    
    iframe{
        --inset: 50px;
        z-index: 0;
        position: absolute;
        top: calc( -1 * var(--inset));
        left: calc( -1 * var(--inset));
        width: calc( 100% + 2 * var(--inset));
        height: calc( 100% + 2 * var(--inset));

    }

   
    
}

.error-bar{
    display: flex;
    font-size: 11px;
    line-height: 14px;
    margin-bottom: 1rem;
    .error-bar__icon{
        flex-grow: 0;
        flex-shrink: 0;
        width: 22px;
        margin-right: 10px;
        position: relative;
        top: -3px;        
    }

    a{
        color: var(--accent);
        text-decoration: none;
    }
}


// Tabs
.AppTabs{
    display: flex;
    width: 100%;
    border-bottom: 1px solid #D9D9D9;
    justify-content: space-between;
    margin-bottom: 5px;
    gap: 6px;
}
.AppTab{
    flex: 1;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 13px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    color: #000;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    height: 55px;
    border-bottom-width: 0;
    flex-grow: 1;
    flex-shrink: 0;
    transition: 333ms;

    &:hover, .App &:hover{
        background-color: #eee;
        border-color: #D9D9D9;
        color: #000;
    }
    
    

    &.active, .App &.active{
        color: #D2A946;
        background: #000000;
        border: 1px solid #000000;

    }

}

.summary-cart{
    margin-top: auto;
    background-color: #fff;
    @include media-breakpoint-up(lg){
        position: absolute;
        bottom: 0;
        right: 0;
        width: 380px;
        padding: var(--padding);
        padding-top: 0;

        box-shadow: #000 0 -5px 10px -13px;
    }
}

.sc-price{
    margin-top: auto;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 27px;
    margin-top: 1em;
    margin-bottom: 0.6em;

    color: #000000;

}

.sc-add-cart{
    display: flex;
    width: 100%;
    .sc-add-cart__amount{
        width: 100px;
    }
    .sc-add-cart__btn{
        width: auto;
        flex-grow: 1;
        .add-to-cart-btn, button.add-to-cart-btn{
            width: 100%;
            text-align: center;
        }

    }
}
.sc-info {
    margin-top: 0.6rem;
    &__link {
        a#{&}, & {
            color: #000;
            font-size: 11px;
            text-decoration: none;
            &:hover {
                color: #000;
                text-decoration: underline;

            }
        }
    }
}

.model-sizes{
    display: flex;
    // position: absolute;
    top: 0px;
    right: 0;

    align-items: baseline;

    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 5px;
    >.btn-primary{
        margin-left: 8px;
        
    }

    @include media-breakpoint-up(md){
        position: absolute;
    }
}

// .Color
.color-list{
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
    justify-content: flex-start;
    display: grid;
    grid-template-columns: repeat(3, calc(33% - 20px));

    gap: 10px 22px;
    padding: 1em 5px;
}
.Color{
    background-color: transparent;
    border: 0;
    text-align: center;
    padding: 3px;
    .Color-name{
        margin-top: 1em;
        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        text-align: center;
        color: #000;
    }

    img{

        border: 1px solid #e7e7e7;

        display: block;
        margin: auto;
        transition: 111ms;
        border-radius: 100px;
        width: 60px;
        height: 60px;
        object-fit: cover;

    }
    &.active{
        img{
            box-shadow: #000 0 0 0 3px;
        }
    }
    &:hover{
        img{
            transform: scale(1.1);
        }
    }
    
}