
.ConfigurationSummary__list{
    display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
    gap: 20px;
    display: grid;
    grid-template-columns: repeat( auto-fill, minmax(180px, 1fr) );
    @media (max-width: 1500px) {
        grid-template-columns: repeat( auto-fill, minmax(128px, 1fr) );

    }
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;

    color: #000000;

    .ConfigurationSummary__list-item{
        // width: 25%;
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.ConfigurationSummary__list-item{
    width: minmax(50%, 25%);
    img{
        margin-bottom: 10px;
        height: 29px;
        width: 29px;
        object-fit: cover;
        border-radius: 100px;
        border: 1px solid #eee;
    }
    .fab-name{
        margin-bottom: 0.5em;
        font-weight: 700;
    }
}

.ConfigurationSummary__title{
    margin-top: 1em;

    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
}