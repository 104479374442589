.AppModal{
    font-family: 'Raleway', 'Open Sans', sans-serif;
}



.h1-top{
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 0.4em;
    margin-top: 8px;
}
.h1{
    
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.1;
    margin-bottom: 0.8em;

    @include media-breakpoint-up(md){
        font-size: 36px;
    }

    b, strong{
        font-weight: 700;
    }
}

.h2{
    
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
}

.summary-note {
    padding-top: 20px;
    margin-bottom: 0;
    font-size: 11px;
}

.fab-label{

font-family: 'Raleway';
font-style: normal;
font-weight: 400;
font-size: 9px;
line-height: 11px;
/* identical to box height */


color: #000000;

}