
.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation{
    background-color: rgba(255, 255, 255, 0.822);
}

.swal2-container.swal2-center>.swal2-popup{
    padding: 30px 20px;
    border-radius: 0; 
    
    box-shadow: rgba(0, 0, 0, 0.616) 0 0 80px -20px;
}


.swal2-container{
    font-family: inherit;
    color: inherit;
    
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
    .swal2-html-container{
        
        font-family: inherit;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 20px;

    }

    .swal2-title{
        color: #000;
        font-size: 13px;
    }
}




.swal2-actions{
    .btn{
        margin-left: 5px;
        margin-right: 5px;
        min-width: 100px;
    }
}