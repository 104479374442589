@import "/src/mixins.scss";


.AccordionItem{

}
.AccordionItem__title{

}

.AccordionItem{
    --acc-padding: 0px;

    background: #FFFFFF;
    /* box shadow */

    // box-shadow: 0px 0px 20px rgba(173, 174, 185, 0.25);
    border-radius: 0;
    padding: var(--acc-padding);

    // margin-bottom: 10px;

    border-bottom: 1px solid #D9D9D9;

    .AccordionItem__title{
        position: relative;

        font-family: 'Raleway';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        padding-top: 1.2em;
        padding-bottom: 1.2em;
        
    }

    .AccordionItem__plus-icon{
        color: #000;
        position: absolute;
        right: 0;
        top: 13px;
        transition: 333ms;
        transform-origin: center;
    }
    
    
    @include media-breakpoint-up(lg){
        // margin-bottom: 20px;
        
        box-shadow: none;
        --acc-padding: 0;
        
    }

    &.is-open{
        .AccordionItem__plus-icon{
            transform: rotate(180deg);
        }
       
        
    }

}
.AccordionItem__title{
    transition: 333ms;
    cursor: pointer;
    &:hover{
        background-color: rgba(238, 238, 238, 0.5);
        // border-color: #000;
        // background-image: linear-gradient(to top, rgba(0, 0, 0, 0.1) 1px, transparent 1px);
        // &:after{
            //     content: '';
            //     position: absolute;
            //     bottom: 0;
            //     right: 0;
            //     left: 0;
            //     box-shadow: #000 0 0 3px 0;
            // }
            // + .Collapse{
                
              
            // }
    }
    
}


.AccordionItem{
    .Collapse{
        

    }
}

