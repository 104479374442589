@import "/src/mixins.scss";

.grid{
    display: grid;
    // height: calc(100% - 100px);
    // height: calc(var(--height-y) - 200px);
    grid-template-columns: 100%;
    grid-template-rows: auto;

    grid-template-areas: 
                        "render"
                        "controls"
                        "summary";
                        
 
    .grid-render{
        display: flex;
        flex-direction: column;

        grid-area: render;
        
    }
    .grid-controls{
        grid-area: controls;
        // position: relative;
        // height: 100%;
    }
    .grid-summary{
        grid-area: summary;
    }
   


    @include media-breakpoint-up(lg){

        grid-template-columns: 1fr 380px;
        grid-template-rows: 1fr auto;
        // grid-template-areas: 
        // "left main right"
        // "bottom bottom bottom";
        grid-template-areas: 
        "render controls"
        "summary controls";
       
        .grid-render{
            // height: 500px;
            // height: calc(var(--height-y) - 500px);
        }
        .grid-controls{
            padding-left: 45px;
            display: flex;
            flex-direction: column;
            height: 100%;
            padding-bottom: 140px;
        }

    }
}
