
button.add-to-cart-btn, .add-to-cart-btn{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 15px;
    text-align: right;

    height: 41px;
    
    color: #000000;

    background-color: #fff;
    border: 2px solid #fff;
    border-left-width: 0;
    border-right-width: 0;
    border-top: 0;


    border-bottom: 2px solid #D9D9D9;
    transition: 333ms;

    padding: 0.7em 1em;
    &:active,
    &:hover{
        background-color: #eee;
    }
}
.AppModal .btn-primary{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    text-align: center;

    height: 27px;
    
    color: #000000;

    background-color: #fff;
    border: 2px solid #fff;
    border-left-width: 0;
    border-right-width: 0;
    border-top: 0;


    border-bottom: 2px solid #D9D9D9;
    transition: 333ms;

    padding: 0.7em 1em;

    &,&:active,&:focus{
        outline: none;
        box-shadow: none;
    }

    &.active{
        background-color: #000000;
        border-bottom-color: #000000;
        color: #D2A946;
    }
    &:active,
    &:hover{
        background-color: #eee;
    }
}
