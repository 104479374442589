@import "/src/mixins";

.Modal__overlay{

    --padding: 1.3rem;
    --padding-overlay: 1.3rem;

    @include media-breakpoint-up(lg){
        --padding: 40px;
        --padding-overlay: 20px;
    }

    background: hsla(0, 0%, 0%, 0.5);
    position: fixed;
    z-index: 111;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    display: none;

    &.is-open{
        display: flex;
    }
}
.Modal{
    display: flex;
    max-width: 900px;
    min-width: 120px;
    max-height: 100%;
    padding: var(--padding);
    padding-right: calc(var(--padding) * 1/3);
    
    position: relative;
    // padding-bottom: 2rem;
    background-color: #fff;
    
    box-shadow: rgba(0, 0, 0, 0.4) 5px 5px 60px -10px;
    @include media-breakpoint-up(md){
        // border-radius: 20px;
        // padding-bottom: 4rem;
        margin-top: var(--padding-overlay);
        max-height: calc(100% - var(--padding-overlay) * 2);
        
    }

}
.Modal__content{
    
    padding-right: calc(var(--padding) * 2/3);
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;
    width: 100%;
}
.Modal__footer{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
        
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 92.99%);
    border-radius: 0px 0px 20px 20px;
    padding: 1rem var(--padding);

}

.Modal__close{
    background-color: transparent;
    border: 0;
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px;
    transition: 333ms;
    color: #000;
    z-index: 99;
    svg{
        display: block;
    }
    &:hover{
        transform: scale(1.2);
    }
}


.AppModal{
    .Modal{
        width: 100%;
        max-width: 1050px;
        // @include media-breakpoint-up(xxl){
        @media (min-width: 1500px){
            max-width: 1250px;
        }
            
        
    }

}

.ArModal{
 
    text-align: center;
}