


.default-wrapper{
    // max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    // padding-left: var(--main-x-gutter);
    // padding-right: var(--main-x-gutter);
    &.no-padding{
        padding-left: 0;
        padding-right: 0;
    }
}

.creators-controls{
    padding: 30px 20px;
    background-color: #fff;
}

.medium-wrapper{
    margin-left: auto;
    margin-right: auto;
    max-width: 810px;
}
.small-wrapper{
    margin-left: auto;
    margin-right: auto;
    max-width: 610px;
}
.tiny-wrapper{
    margin-left: auto;
    margin-right: auto;
    max-width: 410px;
}
